import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import _ from 'lodash';
import { messageInfo } from '@/components/popup/message';
import { config } from '@/config';
import { auth } from '@/lib/auth/firebase-auth';

enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface ResponseMessage {
  message: string;
  code?: number;
}

export type ResponseError = ResponseMessage & {
  field?: string;
  stack?: any;
};

export interface Meta {
  meta?: { [key: string]: any };
}

export type Data<D> = Meta & {
  data: D;
};

type RequestConfig = AxiosRequestConfig;

export class ApiClient {
  private baseConfig: Partial<RequestConfig>;

  public constructor(apiClientConfig: object) {
    this.baseConfig = apiClientConfig;
  }

  public config(apiClientConfig: RequestConfig) {
    this.baseConfig = apiClientConfig;
  }

  public async get<D>(
    path: string,
    params: object | [] | FormData = {},
    apiClientConfig: RequestConfig = {},
  ) {
    const method = RequestMethod.GET;
    return await this.request<D>(path, method, params, apiClientConfig);
  }

  public async post<D>(
    path: string,
    data: object | [] | FormData = {},
    apiClientConfig: RequestConfig = {},
  ) {
    const method = RequestMethod.POST;
    return await this.request<D>(path, method, data, apiClientConfig);
  }

  public async patch<D>(
    path: string,
    data: object | [] | FormData = {},
    apiClientConfig: RequestConfig = {},
  ) {
    const method = RequestMethod.PATCH;
    return await this.request<D>(path, method, data, apiClientConfig);
  }

  public async put<D>(
    path: string,
    data: object | [] | FormData = {},
    apiClientConfig: RequestConfig = {},
  ) {
    const method = RequestMethod.PUT;
    return await this.request<D>(path, method, data, apiClientConfig);
  }

  public async delete<D>(path: string, apiClientConfig: RequestConfig = {}) {
    const method = RequestMethod.DELETE;
    return await this.request<D>(path, method, {}, apiClientConfig);
  }

  public async deleteWithPayload<D>(
    path: string,
    data: object | [] | FormData = {},
    apiClientConfig: RequestConfig = {},
  ) {
    const method = RequestMethod.DELETE;
    return await this.request<D>(path, method, data, apiClientConfig);
  }

  private async request<ReturnType>(
    path: string,
    method: RequestMethod,
    data: object | [] | FormData = {},
    options: RequestConfig,
  ): Promise<ReturnType> {
    let headers;

    const idToken = await auth.currentUser?.getIdToken();

    if (idToken) {
      headers = { Authorization: 'Bearer ' + idToken };
    } else {
      headers = {};
    }

    const baseConfig: RequestConfig = {
      url: path,
      method: method.toString() as RequestMethod,
      params: decamelizeKeys(method === RequestMethod.GET ? data : {}),
      data: decamelizeKeys(method !== RequestMethod.GET ? data : {}),
      ...options,
      headers: {
        ...options.headers,
        ...headers,
        'x-platform': 'web',
      },
    };

    try {
      const response = await axios.request({
        ...this.baseConfig,
        ...baseConfig,
      });
      const responseData =
        options.responseType !== 'blob'
          ? camelizeKeys(response.data as object, (key: any, convert: any) =>
              /[-]+/.test(key) ? key : convert(key),
            )
          : response.data;

      // @ts-ignore
      return responseData as ResponseType;
    } catch (error) {
      const errorResponse = _.get(error, 'response') as AxiosResponse<
        ResponseMessage | ResponseError
      >;

      if (errorResponse) {
        const { data: errorData, status: errorStatus } = errorResponse;
        let message = _.get(errorData, 'message');

        switch (errorStatus) {
          case 401:
            if (message !== 'Unauthorized') {
              message = message || '로그인 유효 시간이 지났습니다\n다시 로그인해주세요';
            } else {
              message = '로그인이 제한된 계정입니다.';
            }
            auth.signOut();
            break;

          case 403:
            message = message || '로그인이 제한된 계정입니다.';
            auth.signOut();
            break;

          case 404:
            message = message || '리소스를 찾을 수 없습니다.';
            break;

          case 500:
            message =
              message ||
              '처리중 오류가 발생하였습니다.\n 소보로에 문의해주세요. (contact@sovoro.kr)';
            break;
        }

        if (!message) {
          message = '작업 수행에 문제가 있습니다.\n개발팀에 문의해주세요';
        }

        if (
          errorData.code !== 40001 &&
          errorData.code !== 42204 &&
          errorData.code !== 42209 &&
          errorData.code !== 40901 &&
          errorData.code !== 42211 &&
          errorData.code !== 42214 &&
          errorData.code !== 42213 &&
          errorData.code !== 40404
        ) {
          messageInfo({
            content: message,
            noIcon: true,
          });
        }

        throw errorResponse;
      } else {
        messageInfo({
          content: '서버통신에 오류가 발생했습니다.\n소보로에 문의해주세요. (contact@sovoro.kr)',
          noIcon: true,
        });
        throw new Error('request failed');
      }
    }
  }
}

const apiClient = new ApiClient({});

apiClient.config({
  baseURL: config.env.apiHost,
});

export default apiClient;
